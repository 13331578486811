import { type PortableTextComponentProps } from '@portabletext/react'

import {
  SanityMediaContentLayout,
  type SanityImageFragment,
} from '@data/sanity/queries/types/image'

import Photo from '@components/photo'
import Link from '@components/link'

// Used for setting the layout of blog post content images and videos
export const mediaContentLayoutClassMap: Record<
  SanityMediaContentLayout,
  string
> = {
  full: 'is-layout-full',
  left: 'is-layout-left',
  right: 'is-layout-right',
}

const Figure = ({ value }: PortableTextComponentProps<SanityImageFragment>) => {
  const photo = (
    <Photo
      image={value}
      className={value.layout && mediaContentLayoutClassMap[value.layout]}
    />
  )

  if (value.link) {
    return (
      <Link link={value.link} className="no-underline">
        {photo}
      </Link>
    )
  }

  return photo
}

export default Figure
