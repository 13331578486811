import {
  type SanityLink,
  type SanityLinkFragment,
} from '@data/sanity/queries/types/link'

import CustomLink from '@components/link'

interface DropdownItemProps {
  item: SanityLinkFragment
  isOpen: boolean
  onClick?: () => void
  className?: string
  linkClassName?: string
}

const DropdownItem = ({
  item,
  isOpen,
  onClick,
  className,
  linkClassName,
}: DropdownItemProps) => {
  const title = 'title' in item && item.title ? item.title : ''

  return (
    <li className={className}>
      <CustomLink
        link={item as SanityLink}
        tabIndex={!isOpen ? -1 : 0}
        onClick={onClick}
        className={linkClassName}
      >
        {title}
      </CustomLink>
    </li>
  )
}

export default DropdownItem
