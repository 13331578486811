import cx from 'classnames'
import { type ButtonHTMLAttributes, useContext, useState } from 'react'

import { type SanityAddToCartButton } from '@data/sanity/queries/types/content'
import { AnalyticsEventName } from '@lib/analytics'
import { AnalyticsContext } from '@lib/analytics-context'
import { CartContext } from '@lib/cart/context'
import { getPrice, getPriceNumber } from '@lib/helpers'
import { ShopContext } from '@lib/shop-context'
import { StringsContext } from '@lib/strings-context'

import Button, { ButtonProps, ButtonSize } from './button'

type AddToCartButtonProps = ButtonProps &
  Pick<SanityAddToCartButton, 'productVariant'> & {
    buttonClassName?: string
    onAddToCartError?: () => void
  }

const AddToCartButton = ({
  variant,
  size,
  color,
  icon,
  iconAlignment,
  children,
  className,
  buttonClassName,
  productVariant,
  onAddToCartError,
}: AddToCartButtonProps & ButtonHTMLAttributes<HTMLButtonElement>) => {
  const { cart, openCartAfterAddingItems, addItemsToCart, toggleCart } =
    useContext(CartContext)
  const { currency, currencyCode, taxRate } = useContext(ShopContext)
  const strings = useContext(StringsContext)
  const { triggerAnalyticsEvent } = useContext(AnalyticsContext)

  const [isAddingToCart, setIsAddingToCart] = useState(false)
  const [isAddToCartError, setIsAddToCartError] = useState(false)

  const handleAddToCart = async () => {
    setIsAddToCartError(false)
    setIsAddingToCart(true)

    const quantity = 1
    const isSuccessful = await addItemsToCart([
      {
        id: productVariant.variantID,
        quantity,
      },
    ])

    if (!isSuccessful && onAddToCartError) {
      onAddToCartError()
    }

    setIsAddingToCart(false)

    if (isSuccessful) {
      triggerAnalyticsEvent(AnalyticsEventName.AddToCart, {
        currency: currencyCode,
        value: getPriceNumber(productVariant.price * quantity),
        items: [
          {
            index: 0,
            item_id: productVariant.variantID,
            // TODO: Get product title for `item_name` and variant options for `item_variant`.
            price: getPriceNumber(productVariant.price),
            discount: productVariant.comparePrice
              ? getPriceNumber(
                  productVariant.comparePrice - productVariant.price,
                )
              : 0,
            quantity,
          },
        ],
      })

      if (openCartAfterAddingItems) {
        toggleCart(true)
      }
    }
  }

  return (
    <span className={cx('flex items-center', className)}>
      <span className={cx('mr-4 text-xs whitespace-nowrap')}>
        {getPrice(productVariant.price, taxRate)} {currency}
      </span>

      <Button
        id="product-add-to-cart-button"
        variant={variant}
        size={size}
        color={color}
        icon={icon}
        iconAlignment={iconAlignment}
        onClick={handleAddToCart}
        className={buttonClassName}
        disabled={
          !cart || !productVariant.inStock || isAddingToCart || isAddToCartError
        }
      >
        {!cart && strings.buttonUnavailable}
        {isAddingToCart && strings.buttonAdding}
        {!productVariant.inStock && strings.productOutOfStock}
        {cart &&
          productVariant.inStock &&
          !isAddingToCart &&
          !isAddToCartError &&
          children}
      </Button>
    </span>
  )
}

export default AddToCartButton
