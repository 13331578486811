import cx from 'classnames'
import { useContext } from 'react'

import { type SanityLink } from '@data/sanity/queries/types/link'
import { type PhotoImage, getImageDimensions } from '@lib/image'
import { type PageType } from '@lib/routes'
import { StringsContext } from '@lib/strings-context'

import CustomLink from '@components/link'
import Photo from '@components/photo'

interface LogoLinkProps {
  id: string
  image: PhotoImage
  className?: string
}

const LogoLink = ({ id, image, className }: LogoLinkProps) => {
  const strings = useContext(StringsContext)

  const imageDimensions = getImageDimensions(image)
  const link: SanityLink = {
    page: {
      _id: `home-page-link-${id}`,
      _type: 'homePage' satisfies PageType,
    },
  }

  return (
    <div
      className={cx(
        'flex flex-col justify-center items-center transition-opacity',
        className,
      )}
    >
      <CustomLink
        link={link}
        aria-label={strings.goHomeLabel}
        nextLinkClassName="flex"
      >
        <Photo
          image={image}
          width={imageDimensions?.width}
          height={imageDimensions?.height}
          priority
          className="inline-block relative overflow-hidden"
          imageClassName="block absolute top-0 right-0 bottom-0 left-0 m-auto min-w-[100%] max-w-[100%] min-h-[100%] max-h-[100%] w-0 h-0 overflow-hidden"
          showPlaceholder={false}
          style={{
            width: imageDimensions?.width,
            height: imageDimensions?.height,
          }}
        />
      </CustomLink>
    </div>
  )
}

export default LogoLink
