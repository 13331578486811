import cx from 'classnames'

import { type SanityImageFragment } from '@data/sanity/queries/types/image'
import { getImageDimensions } from '@lib/image'

import Photo from '@components/photo'

interface ProductOptionItemThumbnailProps {
  optionThumbnail: SanityImageFragment
  imageWidth: number
  isActive?: boolean
  isCrossed?: boolean
}

const ProductOptionItemThumbnail = ({
  optionThumbnail,
  imageWidth,
  isActive = false,
  isCrossed = false,
}: ProductOptionItemThumbnailProps) => {
  // Find image height based on image width and original aspect ratio
  // const optionThumbnailDimensions = getImageDimensions(optionThumbnail)
  // const imageAspectRatio = optionThumbnailDimensions
  //   ? optionThumbnailDimensions.height / optionThumbnailDimensions.width
  //   : 1

  const imageAspectRatio = 5 / 7
  const imageHeight = imageWidth * imageAspectRatio

  return (
    <div
      className={cx('relative', {
        'shadow-border-black': isActive,
      })}
    >
      <Photo
        image={{ ...optionThumbnail, customRatio: imageAspectRatio }}
        showPlaceholder={false}
        width={imageWidth}
        height={imageHeight}
      />
      {isCrossed && <div className="absolute -inset-1 is-crossed" />}
    </div>
  )
}

export default ProductOptionItemThumbnail
