import cx from 'classnames'
import { useContext } from 'react'

import {
  type SanityProductGalleryPhoto,
  type SanityProductOption,
  type SanityProductOptionSetting,
  type SanityProductVariantFragment,
} from '@data/sanity/queries/types/product'
import { hasObject } from '@lib/helpers'
import {
  getFallbackVariantFromOptions,
  getVariantFromOptions,
} from '@lib/product'
import { type OptionValue } from '@lib/product-card'
import { getProductListingThumbnail } from '@lib/product-images'
import { StringsContext } from '@lib/strings-context'

import { ButtonVariant, getButtonStyles } from '@components/buttons/button'
import RadioItem from '@components/radio-item'
import Swatch from '@components/swatch'
import ProductOptionItemThumbnail from './product-option-item-thumbnail'

interface ProductOptionItemProps {
  value: string
  option: SanityProductOption
  galleryPhotos: SanityProductGalleryPhoto[]
  optionSettings: SanityProductOptionSetting[]
  variants: SanityProductVariantFragment[]
  activeVariant: SanityProductVariantFragment
  strictMatch: boolean
  thumbnailWidth: number
  asButton?: boolean
  isActive?: boolean
  inProductCard?: boolean
  onPreview?: (variant?: SanityProductVariantFragment) => void
}

const ProductOptionItem = ({
  value,
  option,
  galleryPhotos,
  optionSettings,
  variants,
  activeVariant,
  strictMatch,
  thumbnailWidth,
  asButton,
  isActive,
  inProductCard,
  onPreview,
}: ProductOptionItemProps) => {
  const strings = useContext(StringsContext)

  const currentOption: OptionValue[] = [
    {
      name: option.name,
      value,
    },
  ]
  const allOptions: OptionValue[] = [
    ...currentOption,
    ...activeVariant.options.filter(
      (variantOption) =>
        variantOption.position !== option.position ||
        variantOption.name !== option.name,
    ),
  ]

  const optionSetting = optionSettings.find(({ forOption }) => {
    const optionParts = forOption?.split(':')

    return optionParts?.[0] === option.name && optionParts?.[1] === value
  })
  const optionThumbnail = getProductListingThumbnail(
    galleryPhotos,
    optionSetting?.forOption,
    optionSetting?.galleryImage,
  )
  const optionColor = optionSetting?.color

  const hasVariants = variants.find(({ options }) =>
    options.every((variantOption) => hasObject(allOptions, variantOption)),
  )
  const newVariant = getVariantFromOptions(
    variants,
    activeVariant.options,
    option.name,
    value,
  )
  const fallbackVariant =
    option.position === 1
      ? getFallbackVariantFromOptions(variants, option.name, value)
      : undefined
  const inStock = variants.find(({ inStock, options }) => {
    if (!inStock) {
      return false
    }

    if (strictMatch) {
      return options.every((variantOption) =>
        hasObject(allOptions, variantOption),
      )
    }

    return options.some((variantOption) =>
      hasObject(currentOption, variantOption),
    )
  })

  const onMouseEnter = () => {
    if (!onPreview) {
      return
    }

    if (!!newVariant || !!fallbackVariant) {
      onPreview(newVariant ?? fallbackVariant)
    }
  }
  const onMouseLeave = () => {
    if (onPreview) {
      onPreview()
    }
  }

  const buttonStyles = getButtonStyles({
    variant: ButtonVariant.OUTLINED,
    isActive,
  })

  // Hide options that do not match with options in 1st position (e.g., sizes that the color doesn't have)
  if (!newVariant && option.position > 1) {
    return null
  }

  return (
    <RadioItem
      value={value}
      className={cx({
        'p-0 bg-transparent': !!optionColor,
        'z-10': isActive,
        [`${cx({
          'mr-1 [&:last-of-type]:mr-2': inProductCard,
          'line-through': !inStock && hasVariants,
          [`${buttonStyles} border-0 shadow-border-black`]: asButton,
        })}`]: !optionThumbnail && !optionColor,
      })}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      {!!optionThumbnail && (
        <ProductOptionItemThumbnail
          imageWidth={thumbnailWidth}
          optionThumbnail={optionThumbnail}
          isActive={isActive}
        />
      )}

      {!optionThumbnail && !!optionColor && (
        <Swatch
          label={strings.productColorOptionLabel
            .replace(/{value}/gi, value)
            .replace(/{name}/gi, option.name.toLowerCase())}
          color={optionColor}
          isActive={isActive}
        />
      )}

      {!optionThumbnail && !optionColor && value}
    </RadioItem>
  )
}

export default ProductOptionItem
