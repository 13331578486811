import cx from 'classnames'
import { motion } from 'framer-motion'
import { forwardRef, useContext, useEffect, useMemo, useState } from 'react'

import { type SanityProductFragment } from '@data/sanity/queries/types/product'
import { AnalyticsEventName } from '@lib/analytics'
import { AnalyticsContext } from '@lib/analytics-context'
import { itemAnimation } from '@lib/animate'
import { CartContext } from '@lib/cart/context'
import { getPriceNumber } from '@lib/helpers'
import { type Filter } from '@lib/product'
import {
  type OptionValue,
  getFilteredProductVariant,
  getProductTitle,
  getSurfacedOption,
} from '@lib/product-card'
import { getLinkPageUrl } from '@lib/routes'
import { ShopContext } from '@lib/shop-context'
import { StringsContext } from '@lib/strings-context'

import ProductAdd from '@blocks/product/product-add'
import ProductOption from '@blocks/product/product-option'
import ProductPrice from '@blocks/product/product-price'
import ProductThumbnail from '@blocks/product/product-thumbnail'
import { ButtonColor, ButtonVariant } from '@components/buttons/button'
import Chip from '@components/chip'
import SimpleLink from '@components/simple-link'

interface ProductCardProps {
  product: SanityProductFragment
  preferredOption?: OptionValue
  activeFilters?: Filter[]
  showThumbnails?: boolean
  showPrice?: boolean
  showOption?: boolean
  showQuickAdd?: boolean
  onClick?: () => void
  className?: string
  titleClassName?: string
}

const ProductCard = forwardRef<HTMLDivElement, ProductCardProps>(
  (
    {
      product,
      preferredOption,
      activeFilters = [],
      showThumbnails = false,
      showPrice = false,
      showOption = false,
      showQuickAdd = false,
      onClick,
      className,
      titleClassName,
    },
    ref,
  ) => {
    const { currencyCode } = useContext(ShopContext)
    const strings = useContext(StringsContext)
    const { openCartAfterAddingItems, addItemsToCart, toggleCart } =
      useContext(CartContext)
    const { triggerAnalyticsEvent } = useContext(AnalyticsContext)

    const [activeVariant, setActiveVariant] = useState(() =>
      getFilteredProductVariant(product, activeFilters ?? [], preferredOption),
    )

    const [title, setTitle] = useState(() =>
      getProductTitle(product, activeVariant ?? undefined, preferredOption),
    )

    const surfacedOptionOverThumbnail = useMemo(
      () =>
        getSurfacedOption(
          product.options,
          product.surfaceOptions?.overThumbnail,
        ),
      [product.options, product.surfaceOptions],
    )

    const surfacedOptionOverTitle = useMemo(
      () =>
        getSurfacedOption(product.options, product.surfaceOptions?.overTitle),
      [product.options, product.surfaceOptions],
    )

    const showSurfacedOptionOverThumbnail =
      showOption && !!surfacedOptionOverThumbnail && !!activeVariant

    const showSurfacedOptionOverTitle =
      showOption && !!surfacedOptionOverTitle && !!activeVariant

    // Get product URL
    const productUrl = getLinkPageUrl('product', product.slug.current, {
      variant: activeVariant ? `${activeVariant.variantID}` : '',
    })

    const handleAddToCart = async () => {
      if (!activeVariant) {
        return
      }

      const quantity = 1
      const isSuccessful = await addItemsToCart([
        {
          id: activeVariant.variantID,
          quantity,
        },
      ])

      if (isSuccessful) {
        triggerAnalyticsEvent(AnalyticsEventName.AddToCart, {
          currency: currencyCode,
          value: getPriceNumber(activeVariant.price * quantity),
          items: [
            {
              index: 0,
              item_id: activeVariant.variantID,
              item_name: product.title,
              item_variant: activeVariant.options
                ?.map((option) => option.value)
                ?.join(', '),
              price: getPriceNumber(activeVariant.price),
              discount: activeVariant.comparePrice
                ? getPriceNumber(
                    activeVariant.comparePrice - activeVariant.price,
                  )
                : 0,
              quantity,
            },
          ],
        })

        if (openCartAfterAddingItems) {
          toggleCart(true)
        }
      }
    }

    useEffect(() => {
      setTitle(
        getProductTitle(product, activeVariant ?? undefined, preferredOption),
      )
    }, [activeVariant, preferredOption, product])

    return (
      <motion.div
        ref={ref}
        variants={itemAnimation}
        className={cx(
          'h-full flex flex-col justify-end relative group z-0',
          className,
        )}
      >
        {!!product.comparePrice && (
          <Chip
            className="absolute top-0 right-0 z-[1]"
            label={strings.productDiscountText.replace(
              /{percent}/gi,
              Math.ceil(
                ((product.comparePrice - product.price) /
                  product.comparePrice) *
                  100,
              ).toString(),
            )}
          />
        )}

        {showThumbnails && !!activeVariant && (
          <div className="relative flex flex-col items-center">
            <SimpleLink href={productUrl} className="w-full">
              <ProductThumbnail
                galleryPhotos={product.galleryPhotos}
                listingPhotos={product.listingPhotos}
                activeVariant={activeVariant}
              />
            </SimpleLink>

            {showQuickAdd && activeVariant.inStock && (
              <div
                className={cx(
                  'absolute bottom-0 inset-x-0 z-30 text-center mx-4 mb-8 opacity-0 invisible',
                  'translate-y-1/2 transition-all will-change-transform',
                  'group-hover:opacity-100 group-hover:visible group-hover:translate-y-0',
                )}
              >
                <ProductAdd
                  variant={ButtonVariant.FILLED}
                  color={ButtonColor.DEFAULT}
                  onClick={handleAddToCart}
                >
                  {strings.buttonAddToCart}
                </ProductAdd>
              </div>
            )}

            {showSurfacedOptionOverThumbnail && (
              <div className="absolute bottom-3 right-3 z-30 hidden group-hover:flex">
                <ProductOption
                  product={product}
                  option={surfacedOptionOverThumbnail}
                  preferredOption={preferredOption}
                  galleryPhotos={product.galleryPhotos ?? []}
                  optionSettings={product.optionSettings ?? []}
                  variants={product.variants ?? []}
                  activeVariant={activeVariant}
                  onChange={setActiveVariant}
                  inProductCard
                  limit={3}
                />
              </div>
            )}
          </div>
        )}

        <div
          className={cx(
            'flex items-start justify-between gap-2 p-3 leading-tight uppercase',
            titleClassName,
          )}
        >
          <SimpleLink
            href={productUrl}
            onClick={onClick}
            onBeforeInput={onClick}
            tabIndex={0}
            className={cx('text-xs block no-underline text-current', {
              'group-hover:hidden': showSurfacedOptionOverTitle,
            })}
          >
            {title}
          </SimpleLink>

          {showSurfacedOptionOverTitle && (
            <ProductOption
              product={product}
              option={surfacedOptionOverTitle}
              preferredOption={preferredOption}
              galleryPhotos={product.galleryPhotos ?? []}
              optionSettings={product.optionSettings ?? []}
              variants={product.variants ?? []}
              activeVariant={activeVariant}
              onChange={setActiveVariant}
              inProductCard
              className="hidden group-hover:flex"
            />
          )}

          {showPrice && (
            <ProductPrice
              className="text-xs"
              price={activeVariant?.price ?? product.price}
              comparePrice={activeVariant?.comparePrice ?? product.comparePrice}
            />
          )}
        </div>
      </motion.div>
    )
  },
)

ProductCard.displayName = 'ProductCard'

export default ProductCard
