import { ReactNode, useContext } from 'react'
import cx from 'classnames'

import { SiteContext } from '@lib/site-context'
import { ProductSearchContext } from '@lib/product-search-context'
import Icon from '@components/icon'

interface ProductSearchToggleProps {
  className?: string
  children?: ReactNode
}

const ProductSearchToggle = ({
  className,
  children,
}: ProductSearchToggleProps) => {
  const { toggleMegaNavigation, toggleMobileMenu } = useContext(SiteContext)
  const { toggleProductSearchDrawer, productSearchDrawer } =
    useContext(ProductSearchContext)

  return (
    <button
      onClick={() => {
        toggleMobileMenu(false)
        toggleMegaNavigation(false)
        toggleProductSearchDrawer(!productSearchDrawer.isOpen)
      }}
      aria-expanded={productSearchDrawer.isOpen ? 'true' : 'false'}
      aria-controls="product-search"
      aria-label={'Search'}
      className={cx(
        'appearance-none no-underline cursor-pointer font-inherit transition-opacity hover:opacity-60',
        className,
      )}
    >
      <span className="hidden lg:inline-flex">{children}</span>

      <Icon
        id="product-search"
        name="Search"
        className="lg:hidden text-current text-xl"
      />
    </button>
  )
}

export default ProductSearchToggle
