import { useContext, useState } from 'react'

import { type SanityMenuFeaturedLinkFragment } from '@data/sanity/queries/types/site'
import { getActiveLinkCount } from '@lib/navigation'
import { SiteContext } from '@lib/site-context'

import DrawerMenu from '@components/drawer-menu'
import MegaNavigationDropdown from './mega-navigation-dropdown'

interface MegaNavigationProps {
  items: SanityMenuFeaturedLinkFragment[]
  headerHeight: number
}

const MegaNavigation = ({ items, headerHeight }: MegaNavigationProps) => {
  const { megaNavigation, toggleMegaNavigation } = useContext(SiteContext)

  const [hasFocus, setHasFocus] = useState(false)
  const [activeDropdownHeight, setActiveDropdownHeight] = useState<number>()

  const dropdowns = items.filter(
    (item) => item._type === 'navDropdown' && 'dropdownItems' in item,
  )
  const activeLinkCount = getActiveLinkCount(
    dropdowns,
    megaNavigation.isOpen,
    megaNavigation.activeId,
  )

  if (dropdowns.length === 0) {
    return null
  }

  return (
    <DrawerMenu
      headerHeight={headerHeight}
      activeDropdownHeight={activeDropdownHeight}
      isOpen={megaNavigation.isOpen}
      hasFocus={hasFocus}
      hasActiveLinks={activeLinkCount > 0}
      onClose={() => toggleMegaNavigation(false)}
      menuClassName="hidden lg:block"
      backgroundClassName="hidden lg:block"
      backdropClassName="hidden lg:block"
    >
      {dropdowns.map((dropdown) => (
        <MegaNavigationDropdown
          key={dropdown._key}
          dropdown={dropdown}
          hasFocus={hasFocus}
          setHasFocus={setHasFocus}
          setActiveDropdownHeight={setActiveDropdownHeight}
        />
      ))}
    </DrawerMenu>
  )
}

export default MegaNavigation
