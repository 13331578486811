import { type PortableTextComponentProps } from '@portabletext/react'
import dynamic from 'next/dynamic'

const BaseLottieAnimation = dynamic(
  () => import('@components/lottie-animation'),
)

interface File {
  assetUrl?: string
}

const LottieAnimation = ({ value }: PortableTextComponentProps<File>) => {
  if (!value.assetUrl) {
    return null
  }

  return <BaseLottieAnimation assetUrl={value.assetUrl} />
}

export default LottieAnimation
