import cx from 'classnames'
import { motion } from 'framer-motion'
import { useContext, useEffect, useRef } from 'react'
import { useResizeObserver } from 'usehooks-ts'

import { swipeAnimation } from '@lib/animate'
import { ProductSearchContext } from '@lib/product-search-context'

import ProductSearchForm from './product-search-form'

interface ProductSearchDropdownProps {
  setHasFocus: (hasFocus: boolean) => void
  setDrawerHeight: (drawerHeight: number) => void
}

const ProductSearchDropdown = ({
  setHasFocus,
  setDrawerHeight,
}: ProductSearchDropdownProps) => {
  const { productSearchDrawer } = useContext(ProductSearchContext)

  const drawerRef = useRef<HTMLDivElement>(null)
  const drawerRectangle = useResizeObserver({
    ref: drawerRef,
  })

  useEffect(() => {
    if (
      productSearchDrawer.isOpen &&
      typeof drawerRectangle.height !== 'undefined'
    ) {
      setDrawerHeight(drawerRectangle.height)
    }
  }, [drawerRectangle.height, productSearchDrawer, setDrawerHeight])

  return (
    <div
      ref={drawerRef}
      id="product-search"
      className={cx(
        'absolute top-0 inset-x-0 z-10 -mt-px overflow-hidden transition-visibility duration-200',
        {
          'delay-[0s] pointer-events-auto': productSearchDrawer.isOpen,
          'invisible delay-[.8s] pointer-events-none':
            !productSearchDrawer.isOpen,
        },
      )}
    >
      <div>
        <div>
          <motion.div
            initial="hide"
            animate={productSearchDrawer.isOpen ? 'show' : 'hide'}
            onAnimationComplete={(definition) =>
              setHasFocus(definition === 'show')
            }
            variants={swipeAnimation}
            className="relative will-change-transform"
          >
            {productSearchDrawer.isOpen && <ProductSearchForm hasPreview />}
          </motion.div>
        </div>
      </div>
    </div>
  )
}

export default ProductSearchDropdown
