import cx from 'classnames'
import { type CSSProperties } from 'react'

type CSSPropertiesWithHeight = CSSProperties & {
  '--h': number
  '--hpx': string
}

interface DrawerMenuBackgroundProps {
  height: number
  className?: string
}

const DrawerMenuBackground = ({
  height,
  className,
}: DrawerMenuBackgroundProps) => {
  const style: CSSPropertiesWithHeight = {
    '--h': height,
    '--hpx': `${height}px`,
  }

  return (
    <div className={cx(className)} style={style}>
      <div className="absolute inset-x-0 h-px bg-header-bg will-change-transform origin-top-left scale-y-[var(--h)] transition-transform duration-[.8s] ease-custom-2 z-20 top-0" />
      <div className="absolute inset-x-0 -mt-px translate-x-0 translate-y-[var(--hpx)] top-0" />
    </div>
  )
}

export default DrawerMenuBackground
