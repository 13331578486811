import { PortableText } from '@portabletext/react'
import {
  type ArbitraryTypedObject,
  type PortableTextBlock,
} from '@portabletext/types'

import AddToCartButton from './portable-text/add-to-cart-button'
import ColoredText from './portable-text/colored-text'
import Figure from './portable-text/figure'
import Divider from './portable-text/divider'
import Link from './portable-text/link'
import LottieAnimation from './portable-text/lottie-animation'
import VideoButton from './portable-text/video-button'
import VideoEmbed from './portable-text/video-embed'
import Table from './portable-text/table'

type ComplexPortableTextBlock = PortableTextBlock | ArbitraryTypedObject

interface ComplexPortableTextProps {
  content: ComplexPortableTextBlock[]
  className?: string
}

const ComplexPortableText = ({
  content,
  className,
}: ComplexPortableTextProps) => {
  return (
    <div className={className}>
      <PortableText
        value={content}
        components={{
          block: {
            none: ({ children }) => <>{children}</>,
            normal: ({ children }) => <p>{children}</p>,
            medium: ({ children }) => <p className="is-medium">{children}</p>,
            large: ({ children }) => <p className="is-large">{children}</p>,
            largeIndent: ({ children }) => (
              <p className="is-large indent-12">{children}</p>
            ),
            blockquote: ({ children }) => <blockquote>{children}</blockquote>,
            h1Large: ({ children }) => <h1 className="is-large">{children}</h1>,
            h1: ({ children }) => <h1 className="is-large">{children}</h1>,
            h2: ({ children }) => <h2>{children}</h2>,
            h3: ({ children }) => <h3>{children}</h3>,
            h4: ({ children }) => <h4>{children}</h4>,
            h5: ({ children }) => <h5>{children}</h5>,
            h1mock: ({ children }) => <p className="is-h1">{children}</p>,
            h2mock: ({ children }) => <p className="is-h2">{children}</p>,
            h3mock: ({ children }) => <p className="is-h3">{children}</p>,
            h4mock: ({ children }) => <p className="is-h4">{children}</p>,
            h5mock: ({ children }) => <p className="is-h5">{children}</p>,
          },
          types: {
            figure: Figure,
            table: Table,
            addToCartButton: AddToCartButton,
            divider: Divider,
            lottieAnimation: LottieAnimation,
            videoButton: VideoButton,
            videoEmbed: VideoEmbed,
          },
          marks: {
            link: Link,
            textColor: ColoredText,
          },
        }}
      />
    </div>
  )
}

export default ComplexPortableText
