import {
  type SanityProductFragment,
  type SanityProductOption,
  type SanityProductVariantFragment,
} from '@data/sanity/queries/types/product'
import { hasObject } from './helpers'
import { type Filter, type FilterValue } from './product'
import { shopifyColorOptionNames } from './shopify/product'

export interface OptionValue {
  name: string
  value: string
}

/**
 * Gets default product option taking into account filters.
 */
export const getDefaultOptionsWithFilters = (
  product: SanityProductFragment,
  activeFilterValues: FilterValue[],
): OptionValue[] => {
  return activeFilterValues
    .map((filter) => {
      const currentFilter = product.filters?.find(
        ({ forOption, slug }) => !!forOption && slug.current === filter.value,
      )

      if (!currentFilter) {
        return null
      }

      const option = currentFilter.forOption.split(':')

      return {
        name: option[0],
        value: option[1],
      } satisfies OptionValue
    })
    .filter(Boolean) as OptionValue[]
}

/**
 * Gets the default product variant based on option values.
 */
const getDefaultProductVariant = (
  product: SanityProductFragment,
  optionValues: OptionValue[],
) => {
  const lastOptionValue =
    optionValues.length > 0 ? optionValues[optionValues.length - 1] : null

  const firstOption = product.options?.[0]
  const firstOptionValue = firstOption
    ? ({
        name: firstOption?.name,
        value: firstOption?.values?.[0],
      } as OptionValue)
    : null

  const optionValue = lastOptionValue ?? firstOptionValue

  if (!optionValue) {
    return null
  }

  return (
    product.variants?.find(({ options }) => hasObject(options, optionValue)) ??
    null
  )
}

/**
 * Gets filter values from filters.
 */
const getFilterValues = (filters: Filter[]): FilterValue[] => {
  return (
    filters.flatMap(({ name, values }) =>
      values.map((value) => ({ name, value })),
    ) ?? []
  )
}

/**
 * Gets the filtered, default or first product variant based on current active filters.
 */
export const getFilteredProductVariant = (
  product: SanityProductFragment,
  activeFilters: Filter[],
  preferredOption?: OptionValue,
) => {
  const activeFilterValues = getFilterValues(activeFilters)
  const defaultOptions = getDefaultOptionsWithFilters(
    product,
    activeFilterValues,
  )
  const defaultVariant = getDefaultProductVariant(product, defaultOptions)
  const firstVariant = product.variants?.[0] ?? null
  const preferredOptionVariant = preferredOption
    ? product.variants?.filter(
        ({ options }) =>
          !!options.find(
            (option) =>
              option.name == preferredOption.name &&
              option.value === preferredOption?.value,
          ),
      )[0]
    : null

  return preferredOptionVariant ?? defaultVariant ?? firstVariant
}

/**
 * Gets product card title based on preferred option.
 */
export const getProductTitle = (
  product: SanityProductFragment,
  variant?: SanityProductVariantFragment,
  preferredOption?: OptionValue,
) => {
  const titleParts = [product.title]
  const preferredOptionTitle = preferredOption
    ? variant?.options?.find((option) => option.name == preferredOption.name)
        ?.value
    : null
  const colorOption = product.options.find((option) =>
    shopifyColorOptionNames.includes(option.name),
  )
  const colorOptionValues = colorOption?.values ?? []

  // Add color to title if preferred option is given and product has more than 1 color
  if (preferredOptionTitle && colorOptionValues.length > 1) {
    titleParts.push(preferredOptionTitle)
  }

  return titleParts.join(' ')
}

/**
 * Gets product option that should be surfaced.
 */
export const getSurfacedOption = (
  productOptions: SanityProductOption[],
  surfaceOption?: string,
) => {
  if (!productOptions || !surfaceOption) {
    return
  }

  const surfacedOptionPosition = parseInt(surfaceOption)

  return productOptions.find(
    (option) =>
      option.position === surfacedOptionPosition && option.values.length > 1,
  )
}
