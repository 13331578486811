import cx from 'classnames'
import { type ReactNode, useState } from 'react'

import {
  type KeenSliderHooks,
  type KeenSliderInstance,
  type KeenSliderOptions,
  useKeenSlider,
} from 'keen-slider/react'
import { deviceType } from 'detect-it'

import { screens } from '@lib/theme'

import IconButton from '@components/buttons/icon-button'

/**
 * Gets slide count per view from slider options.
 */
export const getSlidesPerView = (
  slider: KeenSliderInstance<unknown, unknown, KeenSliderHooks>,
) => {
  const slideOptions = slider.options.slides

  if (
    slideOptions &&
    typeof slideOptions === 'object' &&
    slideOptions.perView &&
    typeof slideOptions.perView === 'number'
  ) {
    return slideOptions.perView
  }
}

interface CarouselProps {
  items: ReactNode[]
  options?: KeenSliderOptions
  hasArrows?: boolean
  className?: string
}

const Carousel = ({ options, items, hasArrows, className }: CarouselProps) => {
  const [currentSlide, setCurrentSlide] = useState(0)
  const [slidesPerView, setSlidesPerView] = useState(1)
  const [sliderRef, slider] = useKeenSlider<HTMLDivElement>({
    slides: {
      spacing: 0,
      perView: items.length > 2 ? 2.15 : 2,
    },
    breakpoints: {
      [`(min-width: ${screens.sm})`]: {
        slides: {
          perView: items.length > 3 ? 3.15 : 3,
        },
      },
      [`(min-width: ${screens.md})`]: {
        slides: {
          perView: items.length > 4 ? 4.15 : 4,
        },
      },
    },
    created(slider) {
      const newSlidesPerView = getSlidesPerView(slider)

      if (newSlidesPerView) {
        setSlidesPerView(newSlidesPerView)
      }
    },
    optionsChanged(slider) {
      const newSlidesPerView = getSlidesPerView(slider)

      if (newSlidesPerView) {
        setSlidesPerView(newSlidesPerView)
      }
    },
    ...options,
  })

  const handlePrevClick = () => {
    const prevIndex = Math.max(
      currentSlide > slideCount - slidesPerView
        ? slideCount - slidesPerView - 1
        : currentSlide - 1,
      0,
    )
    slider.current?.moveToIdx(prevIndex)
    setCurrentSlide(prevIndex)
  }

  const handleNextClick = () => {
    const nextIndex = Math.min(currentSlide + 1, slideCount - slidesPerView)
    slider.current?.moveToIdx(nextIndex)
    setCurrentSlide(nextIndex)
  }

  const slideCount = slider.current?.track?.details?.slides?.length ?? 0

  return (
    <div className={cx(className, 'relative')}>
      <div
        className="flex relative overflow-hidden will-change-transform touch-action touch-action-pan-y keen-slider"
        ref={sliderRef}
      >
        {items.map((item, index) => (
          <div className="keen-slider__slide" key={index}>
            {item}
          </div>
        ))}
      </div>

      {hasArrows && slideCount > slidesPerView && (
        <div className="z-10 absolute bottom-3 right-3 flex gap-x-2">
          <IconButton
            type="button"
            onClick={handlePrevClick}
            disabled={currentSlide === 0}
            icon="ChevronLeft"
            className="!p-1 border-0"
          />

          <IconButton
            type="button"
            onClick={handleNextClick}
            disabled={currentSlide === slideCount - slidesPerView}
            icon="ChevronRight"
            className="!p-1 border-0"
          />
        </div>
      )}
    </div>
  )
}

export default Carousel
