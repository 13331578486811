import { useContext } from 'react'
import cx from 'classnames'

import { SiteContext } from '@lib/site-context'
import { StringsContext } from '@lib/strings-context'
import { ProductSearchContext } from '@lib/product-search-context'
import Hamburger from './hamburger'

interface MobileMenuToggleProps {
  className?: string
}

const MobileMenuToggle = ({ className }: MobileMenuToggleProps) => {
  const strings = useContext(StringsContext)
  const { mobileMenu, toggleMobileMenu } = useContext(SiteContext)
  const { toggleProductSearchDrawer } = useContext(ProductSearchContext)

  return (
    <button
      onClick={() => {
        toggleProductSearchDrawer(false)
        toggleMobileMenu(!mobileMenu.isOpen)
      }}
      aria-expanded={mobileMenu.isOpen ? 'true' : 'false'}
      aria-controls="mobile-nav"
      aria-label={strings.mobileMenuLabel}
      className={cx(
        'relative z-60 appearance-none no-underline cursor-pointer font-inherit',
        className,
      )}
    >
      <Hamburger isOpened={mobileMenu.isOpen} className="text-current" />
    </button>
  )
}

export default MobileMenuToggle
