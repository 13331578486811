import cx from 'classnames'

interface DrawerMenuBackdropProps {
  isOpen: boolean
  onClose: () => void
  className?: string
}

const DrawerMenuBackdrop = ({
  isOpen,
  onClose,
  className,
}: DrawerMenuBackdropProps) => {
  return (
    <div
      role="presentation"
      onClick={onClose}
      onBeforeInput={onClose}
      className={cx(
        'fixed inset-0 z-0 bg-backdrop bg-opacity-40 transition-opacity duration-150 ease-linear',
        {
          'opacity-0 pointer-events-none': !isOpen,
          'opacity-100 pointer-events-auto': isOpen,
        },
        className,
      )}
    />
  )
}

export default DrawerMenuBackdrop
