import { useContext, useState } from 'react'

import { ProductSearchContext } from '@lib/product-search-context'

import DrawerMenu from '@components/drawer-menu'
import ProductSearchDropdown from './product-search-dropdown'

interface ProductSearchDrawerProps {
  headerHeight: number
}

const ProductSearchDrawer = ({ headerHeight }: ProductSearchDrawerProps) => {
  const { productSearchDrawer, toggleProductSearchDrawer } =
    useContext(ProductSearchContext)

  const [hasFocus, setHasFocus] = useState(false)
  const [drawerHeight, setDrawerHeight] = useState<number>()

  return (
    <DrawerMenu
      headerHeight={headerHeight}
      activeDropdownHeight={drawerHeight}
      isOpen={productSearchDrawer.isOpen}
      hasFocus={hasFocus}
      hasActiveLinks={true}
      onClose={() => toggleProductSearchDrawer(false)}
    >
      <ProductSearchDropdown
        setHasFocus={setHasFocus}
        setDrawerHeight={setDrawerHeight}
      />
    </DrawerMenu>
  )
}

export default ProductSearchDrawer
