import axios from 'axios'
import cx from 'classnames'
import { useRouter } from 'next/router'
import {
  type BaseSyntheticEvent,
  useContext,
  useEffect,
  useState,
  useCallback,
  useRef,
} from 'react'
import { useForm } from 'react-hook-form'
import { useDebounceCallback } from 'usehooks-ts'

import { type SanityProductFragmentWithScore } from '@data/sanity/queries/types/product'

import { getProductKey } from '@lib/product'
import { ProductSearchContext } from '@lib/product-search-context'
import { StringsContext } from '@lib/strings-context'
import { type ProductSearchResult } from '@pages/api/product/search'

import IconButton from '@components/buttons/icon-button'
import InputField from '@components/input-field'
import { ButtonVariant } from '@components/buttons/button'
import ProductCard from '@blocks/shop/product-card'
import Carousel from '@components/carousel'

interface ProductSearchFormValues {
  query: string
}

interface ProductSearchFormProps {
  className?: string
  hasPreview?: boolean
}

const ProductSearchForm = ({
  className,
  hasPreview,
}: ProductSearchFormProps) => {
  const { query, updateQuery, toggleProductSearchDrawer } =
    useContext(ProductSearchContext)
  const strings = useContext(StringsContext)
  const router = useRouter()
  const queryRef = useRef('')
  const [isPreviewProductsLoading, setIsPreviewProductsLoading] =
    useState(false)
  const [previewProducts, setPreviewProducts] = useState<
    SanityProductFragmentWithScore[]
  >([])
  const { handleSubmit, register, setFocus } =
    useForm<ProductSearchFormValues>()
  const queryRegister = register('query', {
    onChange: (event) => {
      queryRef.current = event.target.value
      updateQuery(queryRef.current)
    },
  })

  // Load product search results based on query
  const loadProducts = useCallback(async (query: string, limit: number) => {
    let newPreviewProducts: SanityProductFragmentWithScore[] = []

    try {
      const productSearchResult = await axios.get<ProductSearchResult>(
        '/api/product/search',
        {
          params: { query, limit },
          headers: { 'Content-Type': 'application/json' },
        },
      )

      if (productSearchResult.data.error) {
        throw new Error(productSearchResult.data.error)
      }

      newPreviewProducts = productSearchResult.data.products
    } catch (error) {
      console.log(error)
    }

    setPreviewProducts(newPreviewProducts)
    setIsPreviewProductsLoading(false)
  }, [])
  const debouncedLoadProducts = useDebounceCallback(loadProducts, 500)

  useEffect(() => {
    if (!hasPreview) {
      return
    }

    if (!query) {
      // If query is missing or empty, clear preview products
      setPreviewProducts([])
      setIsPreviewProductsLoading(false)
      return
    }

    setIsPreviewProductsLoading(true)
    debouncedLoadProducts(query, 6)
  }, [debouncedLoadProducts, query, hasPreview])

  const handleRouteChange = () => {
    router.push(`/shop/search?query=${query}`)
    toggleProductSearchDrawer(false)
  }

  const onSubmit = (
    productSearchFormValues: ProductSearchFormValues,
    event?: BaseSyntheticEvent,
  ) => {
    event?.preventDefault()

    if (!productSearchFormValues.query) {
      setFocus('query')
      return
    }

    handleRouteChange()
  }

  const hasPreviewProducts =
    !isPreviewProductsLoading && previewProducts.length > 0

  return (
    <div>
      <form
        className={cx(
          'flex items-center px-4',
          {
            'border-b border-gray':
              !hasPreview ||
              (hasPreview && (isPreviewProductsLoading || hasPreviewProducts)),
          },
          className,
        )}
        onSubmit={handleSubmit(onSubmit)}
      >
        <InputField
          id="product-search-query"
          type="text"
          formRegister={queryRegister}
          placeholder={strings.searchPlaceholder}
          value={query}
          isUnstyled
          inputClassName="flex w-full py-6 uppercase placeholder:text-gray-dark bg-transparent"
        />

        <IconButton
          icon="Cross"
          className="text-sm !p-0"
          variant={ButtonVariant.LINK}
          onClick={() => {
            updateQuery('')
            toggleProductSearchDrawer(false)
          }}
          type="button"
        />
      </form>
      {hasPreview && hasPreviewProducts && (
        <>
          <div className="py-4 text-center rc">
            <button
              onClick={handleRouteChange}
              className="uppercase text-xs underline hover:no-underline"
            >
              {strings.searchResultShowAllProducts}
            </button>
          </div>

          <Carousel
            items={previewProducts?.map((product, index) => (
              <div key={index}>
                <ProductCard
                  key={getProductKey(product)}
                  product={product}
                  showThumbnails
                  showOption
                  showPrice
                />
              </div>
            ))}
          />
        </>
      )}

      {hasPreview && isPreviewProductsLoading && (
        <p className="py-4 text-center uppercase text-xs">
          {strings.searchingProducts}
        </p>
      )}
    </div>
  )
}

export default ProductSearchForm
