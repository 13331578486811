import { type PortableTextComponentProps } from '@portabletext/react'
import cx from 'classnames'

import { type SanityDivider } from '@data/sanity/queries/types/content'

type DividerValue = SanityDivider

const Divider = ({ value }: PortableTextComponentProps<DividerValue>) => {
  const { faded } = value

  return (
    <hr
      className={cx('bg-current', {
        'opacity-30': faded,
      })}
    />
  )
}

export default Divider
