import { useContext } from 'react'

import { ProductSearchContext } from '@lib/product-search-context'
import { SiteContext } from '@lib/site-context'

interface DropdownButtonProps {
  id: string
  title: string
  className?: string
}

const DropdownButton = ({ id, title, className }: DropdownButtonProps) => {
  const { megaNavigation, toggleMegaNavigation } = useContext(SiteContext)
  const { toggleProductSearchDrawer } = useContext(ProductSearchContext)

  const isActive = megaNavigation?.activeId === id

  return (
    <button
      aria-expanded={isActive}
      aria-controls={`meganav-${id}`}
      onClick={() => {
        toggleProductSearchDrawer(false)
        toggleMegaNavigation(!isActive ? true : 'toggle', id)
      }}
      className={className}
    >
      {title}
    </button>
  )
}

export default DropdownButton
