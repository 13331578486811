import cx from 'classnames'
import { memo, useContext, useEffect, useRef } from 'react'
import { useRouter } from 'next/router'

import { SiteContext } from '@lib/site-context'
import { type SanityPromoSettings } from '@data/sanity/queries/types/site'

import CustomLink from '@components/link'

type PromoBarProps = SanityPromoSettings

const PromoBar = memo(
  ({ enabled, text, link, display, saleMode }: PromoBarProps) => {
    const ref = useRef<HTMLDivElement>(null)

    const {
      promoHeight,
      setPromoHeight,
      promoVisibleHeight,
      setPromoVisibleHeight,
    } = useContext(SiteContext)

    const router = useRouter()

    const type = display?.trim()

    useEffect(() => calculateAndSetVisibleHeight(), [])

    useEffect(() => {
      window.addEventListener('scroll', calculateAndSetVisibleHeight)

      return () => {
        window.removeEventListener('scroll', calculateAndSetVisibleHeight)
      }
    }, [ref])

    const calculateAndSetVisibleHeight = () => {
      const rectangle = ref.current?.getBoundingClientRect()

      if (!rectangle) {
        return
      }

      setPromoHeight(rectangle.height)

      const value = rectangle.height - Math.abs(rectangle.top)

      if (value >= 0) {
        setPromoVisibleHeight(value)
      }
    }

    // Check that promo bar is enabled, type and text are given and the page matches, if type is homepage
    if (
      !enabled ||
      !type ||
      !text ||
      (type === 'home' && router.asPath !== '/')
    ) {
      return null
    }

    return (
      <div
        className={cx(
          'relative z-60 text-center whitespace-nowrap overflow-x-auto overflow-y-hidden',
          {
            'bg-accent text-black': !saleMode,
            'bg-darkRed text-white': saleMode,
          },
        )}
        ref={ref}
      >
        {/* Sorry, not sorry... */}
        <style>{`
        :root {
          --promoHeight: ${promoHeight}px;
          --promoVisibleHeight: ${promoVisibleHeight}px;
        }
      `}</style>

        <div className="inline-flex px-8 py-2 text-xs">
          {link && (
            <CustomLink link={{ page: link }} className="block">
              {text}
            </CustomLink>
          )}
          {!link && <>{text}</>}
        </div>
      </div>
    )
  },
)

PromoBar.displayName = 'PromoBar'

export default PromoBar
