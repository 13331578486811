import { type ReactElement, type ReactNode, createContext } from 'react'

interface RadioGroupContextProps {
  value: string
  items: ReactElement[]
  onChange: (value: string) => void
}

interface RadioItemContextProps {
  index: number
}

interface RadioGroupProviderProps {
  value: string
  items: ReactElement[]
  onChange: (value: string) => void
  children: ReactNode
}

interface RadioItemProviderProps {
  index: number
  children: ReactNode
}

export const RadioGroupContext = createContext<RadioGroupContextProps>({
  value: '',
  items: [],
  onChange: () => null,
})

export const RadioItemContext = createContext<RadioItemContextProps>({
  index: 0,
})

export const RadioGroupProvider = ({
  value,
  items,
  onChange,
  children,
}: RadioGroupProviderProps) => {
  return (
    <RadioGroupContext.Provider
      value={{
        value,
        items,
        onChange,
      }}
    >
      {children}
    </RadioGroupContext.Provider>
  )
}

export const RadioItemProvider = ({
  index,
  children,
}: RadioItemProviderProps) => {
  return (
    <RadioItemContext.Provider
      value={{
        index,
      }}
    >
      {children}
    </RadioItemContext.Provider>
  )
}
