import cx from 'classnames'

import { type SanityImageFragment } from '@data/sanity/queries/types/image'

import LogoLink from './logo-link'

interface LogoProps {
  logo?: SanityImageFragment
  logoSymbol?: SanityImageFragment
  isLogoSymbol?: boolean
  className?: string
}

const Logo = ({ logo, logoSymbol, isLogoSymbol, className }: LogoProps) => {
  const islogoSymbolVisible = !!logoSymbol?.asset && isLogoSymbol

  return (
    <div className={cx('relative', className)}>
      {logo?.asset && (
        <LogoLink
          id="logo"
          image={logo}
          className={cx('transition-opacity relative', {
            'opacity-0 z-0': islogoSymbolVisible,
            'z-10': !islogoSymbolVisible,
          })}
        />
      )}

      {logoSymbol?.asset && (
        <LogoLink
          id="logo-symbol"
          image={logoSymbol}
          className={cx('transition-opacity absolute inset-0', {
            'opacity-0': !islogoSymbolVisible,
          })}
        />
      )}
    </div>
  )
}

export default Logo
