import cx from 'classnames'

import {
  type SanityProductFragment,
  type SanityProductGalleryPhoto,
  type SanityProductOption,
  type SanityProductOptionSetting,
  type SanityProductVariantFragment,
} from '@data/sanity/queries/types/product'
import {
  getFallbackVariantFromOptions,
  getVariantFromOptions,
} from '@lib/product'
import { getLinkPageUrl } from '@lib/routes'
import { type OptionValue } from '@lib/product-card'

import RadioGroup from '@components/radio-group'
import SimpleLink from '@components/simple-link'
import ProductOptionItem from './product-option-item'

interface ProductOptionProps {
  label?: string
  option: SanityProductOption
  preferredOption?: OptionValue
  galleryPhotos: SanityProductGalleryPhoto[]
  optionSettings: SanityProductOptionSetting[]
  product: SanityProductFragment
  variants: SanityProductVariantFragment[]
  activeVariant: SanityProductVariantFragment
  selectedOptions?: string[]
  onChange: (variant: SanityProductVariantFragment) => void
  onClick?: (option?: string) => void
  onPreview?: (variant?: SanityProductVariantFragment) => void
  strictMatch?: boolean
  inProductCard?: boolean
  limit?: number
  className?: string
  groupClassName?: string
}

const ProductOption = ({
  label,
  option,
  preferredOption,
  galleryPhotos,
  optionSettings,
  product,
  variants,
  activeVariant,
  selectedOptions,
  inProductCard,
  limit,
  onChange,
  onClick,
  onPreview,
  strictMatch = true,
  className,
  groupClassName,
}: ProductOptionProps) => {
  const selectedValue =
    activeVariant.options.find((variantOption) => {
      if (!selectedOptions) {
        return variantOption.name === option.name
      }

      return (
        variantOption.name === option.name &&
        selectedOptions.includes(variantOption.name)
      )
    })?.value ?? ''

  const handleChange = (value: string) => {
    if (onClick) {
      onClick(option.name)
    }

    let newVariant = getVariantFromOptions(
      variants,
      activeVariant.options,
      option.name,
      value,
    )

    if (!newVariant) {
      newVariant = getFallbackVariantFromOptions(variants, option.name, value)
    }

    if (!!newVariant && newVariant.variantID !== activeVariant.variantID) {
      onChange(newVariant)
    }
  }

  const optionValues =
    inProductCard && limit ? option.values.slice(0, limit) : option.values

  const sortedOptionValues = [
    optionValues.includes(preferredOption?.value ?? '') &&
      preferredOption?.value,
    ...optionValues.filter((value) => value !== preferredOption?.value),
  ]

  const productUrl = getLinkPageUrl('product', product.slug.current, {
    variant: activeVariant ? `${activeVariant.variantID}` : '',
  })

  return (
    <div
      className={cx(
        'flex',
        {
          'items-stretch': inProductCard,
          'flex-col gap-y-2': !inProductCard,
        },
        className,
      )}
    >
      {!!label && (
        <div className="text-xs uppercase">{`${label}: ${selectedValue}`}</div>
      )}

      <RadioGroup
        value={selectedValue}
        onChange={handleChange}
        className={cx(
          'flex flex-wrap text-xs',
          {
            'gap-[1px]': !inProductCard,
          },
          groupClassName,
        )}
      >
        {sortedOptionValues.map((value) => {
          if (!value) {
            return
          }

          const isActive =
            !!selectedValue &&
            activeVariant.options.some(
              (variantOption) =>
                variantOption.position === option.position &&
                variantOption.value === value,
            )

          return (
            <ProductOptionItem
              key={value}
              value={value}
              option={option}
              galleryPhotos={galleryPhotos}
              optionSettings={optionSettings}
              variants={variants}
              activeVariant={activeVariant}
              strictMatch={strictMatch}
              onPreview={inProductCard ? onPreview : undefined}
              thumbnailWidth={inProductCard ? 36 : 60}
              asButton={!inProductCard}
              isActive={isActive}
              inProductCard={inProductCard}
            />
          )
        })}
      </RadioGroup>

      {limit && option.values.length > limit && (
        <SimpleLink
          href={productUrl}
          className="flex items-center justify-center leading-none w-9 border border-gray-light min-h-6"
        >
          +
        </SimpleLink>
      )}
    </div>
  )
}

export default ProductOption
