import cx from 'classnames'
import { type PortableTextComponentProps } from '@portabletext/react'
import dynamic from 'next/dynamic'

import { type SanityVideoEmbed } from '@data/sanity/queries/types/content'
import { getYouTubeVideoId } from '@lib/video'
import { mediaContentLayoutClassMap } from './figure'

const MuxVideo = dynamic(() => import('@components/video/mux-video'))

const VideoEmbed = ({
  value,
}: PortableTextComponentProps<SanityVideoEmbed>) => {
  switch (value.type) {
    case 'youtube': {
      const { youtubeVideoUrl, layout } = value
      const youtubeVideoId = getYouTubeVideoId(youtubeVideoUrl)

      if (!youtubeVideoId) {
        return null
      }

      return (
        <div
          className={cx(
            'video relative pt-[calc((9/16)*100%)]',
            layout && mediaContentLayoutClassMap[layout],
          )}
        >
          <div className="absolute inset-0">
            <iframe
              src={`https://www.youtube.com/embed/${youtubeVideoId}`}
              title="YouTube video player"
              allow="encrypted-media"
              allowFullScreen
              width="100%"
              height="100%"
              className="border-0"
            ></iframe>
          </div>
        </div>
      )
    }

    case 'mux': {
      const { muxVideo, aspectRatio, settings, layout } = value

      if (!muxVideo) {
        return null
      }

      return (
        <div
          className={cx('video', layout && mediaContentLayoutClassMap[layout])}
        >
          <MuxVideo
            video={muxVideo}
            showControls={settings?.controls}
            autoplay={settings?.autoplay}
            loop={settings?.loop}
            muted={settings?.muted}
            customAspectRatio={aspectRatio}
          />
        </div>
      )
    }

    default: {
      return null
    }
  }
}

export default VideoEmbed
