import { type SanityFooterSettings } from '@data/sanity/queries/types/site'

import Menu from '@blocks/navigation/menu'
import Newsletter from '@blocks/newsletter'
import LanguageSwitch from '@components/language-switch'

type FooterProps = SanityFooterSettings & {
  children?: React.ReactNode
}

const Footer = ({ blocks, copyright, children }: FooterProps) => (
  <footer className="bg-pageBG text-pageText">
    {children}

    <div className="container grid sm:grid-cols-2 lg:grid-cols-4 py-10 gap-x-5 gap-y-12 border-t border-gray">
      {blocks?.map((block) => (
        <div key={block._key} className="flex flex-col gap-y-5">
          <h3>{block.title}</h3>

          {'description' in block && (
            <p className="text-xs">{block.description}</p>
          )}

          {block._type === 'menu' && <Menu items={block.items} isFooterMenu />}

          {block._type === 'newsletter' && (
            <Newsletter
              id={block._key}
              service={block.service}
              listId={block.listId}
              submit={block.submit}
              successMsg={block.successMsg}
              errorMsg={block.errorMsg}
              terms={block.terms}
              isMinimal
            />
          )}
        </div>
      ))}
    </div>

    <div className="container flex flex-col sm:flex-row justify-between border-t border-gray-light py-3 gap-3">
      {copyright && <p className="uppercase text-xs">{copyright}</p>}
      <LanguageSwitch hasCurrency buttonClassName="text-xs uppercase" />
    </div>
  </footer>
)

export default Footer
