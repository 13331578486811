import { useContext } from 'react'
import cx from 'classnames'

import { type SanityMenuFeaturedItem } from '@data/sanity/queries/types/site'
import { type SanityProductFragment } from '@data/sanity/queries/types/product'
import {
  type PageType,
  getFormattedQueryString,
  getLinkPageUrl,
} from '@lib/routes'
import { StringsContext } from '@lib/strings-context'

import ProductCard from '@blocks/shop/product-card'
import SimpleLink from '@components/simple-link'
import Photo from '@components/photo'

interface FeaturedItemsProps {
  items: SanityMenuFeaturedItem[]
  onClick?: () => void
  className?: string
}

const isProductType = (link: any): link is SanityProductFragment => {
  return link._type === 'product'
}

const FeaturedItems = ({ items, onClick, className }: FeaturedItemsProps) => {
  const strings = useContext(StringsContext)

  if (items.length === 0) {
    return null
  }

  const hasOnlyNonProductItems = items.every(
    (item) => !isProductType(item.link),
  )

  return (
    <div className={cx('grid grid-cols-3', className)}>
      {items.map((item, index) => {
        const key = `${index}-${item.link._type}-featured-menu-item-${item.link.title}`

        const isWide =
          (hasOnlyNonProductItems && items.length < 3 && index == 1) ||
          (!hasOnlyNonProductItems && items.length < 3)

        const isFullWidth = hasOnlyNonProductItems && items.length === 1

        if (isProductType(item.link)) {
          return (
            <ProductCard
              key={key}
              product={item.link as SanityProductFragment}
              showThumbnails
              showPrice
              onClick={onClick}
              titleClassName="!pl-0"
            />
          )
        }

        const url = `${getLinkPageUrl(
          item.link._type as PageType,
          item.link.slug?.current,
        )}${getFormattedQueryString(item.link.queryString)}`

        const image = !!item.image
          ? item.image
          : !!item.link.image
            ? item.link.image
            : null

        const hoverText =
          item.link._type === 'blogPost'
            ? strings.blogPostReadMore
            : item.link._type === 'collection'
              ? strings.buttonShopNow
              : null

        return (
          <SimpleLink
            key={key}
            href={url}
            className={cx('flex flex-col group', {
              'col-span-2': isWide,
              'col-span-3': isFullWidth,
            })}
          >
            {!!image && (
              <Photo
                image={{
                  ...image,
                  customRatio: isWide ? 10 / 7 : isFullWidth ? 28 / 13 : 5 / 7,
                }}
                imageClassName="h-full w-full object-cover"
                fadeIn
              />
            )}

            <h3 className="py-3 pr-3">
              {item.title ?? item.link.title}{' '}
              {hoverText && (
                <span className="hidden group-hover:inline">— {hoverText}</span>
              )}
            </h3>
          </SimpleLink>
        )
      })}
    </div>
  )
}

export default FeaturedItems
